import Vue from 'vue';
import Router from 'vue-router';
import BadRequest from '@/views/BadRequest.vue';
import NotFound from '@/views/NotFound.vue';

Vue.use(Router);

const routerConfig = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/init',
      name: 'Init',
      component: () =>
        import(/* webpackChunkName: "init" */ '@/views/Init.vue'),
    },
    {
      path: '/redirect',
      name: 'Redirect',
      component: () =>
        import(/* webpackChunkName: "redirect" */ '@/views/Redirect.vue'),
    },
    {
      path: '/400',
      name: '400',
      component: BadRequest,
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
};

const RouterClass = class {
  constructor(config) {
    const router = new Router(config);

    /* auth conroll on route change and stock previour Route */
    router.beforeEach((to, from, next) => {
      // guard invalid url
      if (to.name !== '400') {
        if (!Vue.prototype.$inValidHost) {
          next({ name: '400' });
        }
      }
      next();
    });
    return router;
  }
};

export default new RouterClass(routerConfig);
