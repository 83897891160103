<template>
  <v-app>
    <v-toolbar app class="primary">
      <v-toolbar-title class="headline text-uppercase white--text">
        <span class="mr-2">HoteKan</span>
        <span class="font-weight-light">Account</span>
      </v-toolbar-title>
    </v-toolbar>

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      //
    };
  },
};
</script>
