import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '@/router';

const mode = process.env.VUE_APP_MODE;

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_TRACKING_ID,
  router,
  autoTracking: {
    exception: mode !== 'development',
    exceptionLogs: mode !== 'production',
  },
});
