import Vue from 'vue';
import '@/init';
import '@/plugins/vue-analytics';
import '@/plugins/vuetify';
import '@/assets/style/common.css';
import Base from './Base.vue';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(Base),
}).$mount('#base');
